<script setup lang="ts">
import Layout from "@/Layouts/Layout.vue"
import { Head, Link } from "@inertiajs/vue3"
import { provide } from "vue"

const props = defineProps<{
	highschoolCount: number
	collegeCount: number
	status?: string
}>()

provide("loginStatus", props.status)
</script>

<template>
	<Layout>
		<Head title="Welcome" />
		<section class="jumbotron flex items-center justify-end">
			<div class="m-40 flex max-w-2xl flex-col items-center gap-y-4">
				<div class="text-center text-5xl text-white drop-shadow-xl">
					ADVERTISE ON {{ highschoolCount.toLocaleString() }} HIGH SCHOOL AND
					{{ collegeCount.toLocaleString() }} COLLEGE STUDENT NEWS WEBSITES
				</div>
				<div>
					<Link href="/marketplace" class="btn btn-primary text-white" as="button"
						>Browse Sites</Link
					>
				</div>
			</div>
		</section>
		<section
			class="parallax hero py-32"
			style="background-image: url(/images/snosites_background.jpg)"
		>
			<div class="hero-overlay bg-opacity-0"></div>
			<div class="hero-content text-center text-neutral-content">
				<div class="">
					<h1 class="mb-5 text-6xl font-extralight">SNO Sites</h1>
					<p class="mb-5 text-xl font-bold">
						SNOAds.com is an exclusive advertising platform for SNO Sites member
						schools.
					</p>
					<a href="https://snosites.com/" class="btn btn-primary text-white"
						>Learn More</a
					>
				</div>
			</div>
		</section>
	</Layout>
</template>
<style scoped>
.jumbotron {
	background-image: url(/images/header_background.jpeg);
	background-size: cover;
	height: 600px;
}

.parallax {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
</style>
